import React, { useMemo, useState, useEffect } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { Topbar } from "./Topbar";
import { HeaderMenuWrapper } from "./header-menu/HeaderMenuWrapper";
import { AnimateLoading } from "../../../_partials/controls";
import Select from "react-select";
import { useHistory, useLocation } from "react-router-dom";
import { PATH } from "../../../../app/config";
import { reactSelectCustom, dashboardOption } from "../../../../app/constant";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Modal } from "react-bootstrap";
import { logout } from "../../../../app/modules/Auth/_redux/authCrud";
import {
  getPolicesByRoleIdAction,
  getPolicesByRoleIdSidebarAction,
} from "../../../../redux/actions";

export function IsNamePermissionAllowed() {
  return IsPermissionAllowed("pt:patientName")
}

export function IsMrnPermissionAllowed() {
  return IsPermissionAllowed("pt:patientMRN")
}


export function IsPermissionAllowed(permission) {
  let policesData = useSelector((state) => state?.clinicsReducer);
  return (
    policesData?.getSidebarPolicesByRoleIdData?.[0]?.policies?.filter(
      (items) => items?.value === permission
    ).length > 0
  );
  //return isPermissionAllowed;
}





export function Header() {
  let policesData = useSelector((state) => state?.clinicsReducer);
  const isPermissionAllowed = (value) => {
    return (
      policesData?.getSidebarPolicesByRoleIdData?.[0]?.policies?.filter(
        (items) => items?.value === value
      ).length > 0
    );
  };

  const isSectionPermissionAllowed = (value) => {
    return (
      policesData?.getSidebarPolicesByRoleIdData?.[0]?.policies?.filter(
        (items) => items?.value.startsWith(value)
      ).length > 0
    );
  };
  const [dashboard, setDashboard] = useState({});
  const { user } = useSelector((state) => state.auth);
  let roleId = user?.data?.roleIds?.[0];
  let dispatch = useDispatch();
  useEffect(() => {
    ;
    if (user?.data?.roleIds?.[0])
      dispatch(getPolicesByRoleIdSidebarAction(user?.data?.roleIds?.[0]));
  }, []);

  const uiService = useHtmlClassService();
  const history = useHistory();
  const location = useLocation();



  const handleOnChange = ({ value }) => {
    if (value) {
      if (value === 1) {
        return history.push(PATH.MAIN_DASHBOARD);
      }
      if (value === 2) {
        return history.push(PATH.IMMUNIZATION_DASHBOARD);
      }
      if (value === 3) {
        return history.push(PATH.ANC_DASHBOARD);
      }
    }
  };

  const layoutProps = useMemo(() => {
    return {
      headerClasses: uiService.getClasses("header", true),
      headerAttributes: uiService.getAttributes("header"),
      headerContainerClasses: uiService.getClasses("header_container", true),
      menuHeaderDisplay: objectPath.get(
        uiService.config,
        "header.menu.self.display"
      ),
    };
  }, [uiService]);

  useEffect(() => {
    handleDashboard(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const manueOptions = [
    { value: 1, label: "Global Dashboard" },
    { value: 2, label: "Immunization" },
    { value: 3, label: "Antenatal Care" },
  ];
  // Modify the options based on viewState and viewState2
  let modifiedOptions = [];
  if (isSectionPermissionAllowed("gd"))
    modifiedOptions = [...modifiedOptions, manueOptions[0]];
  if (isSectionPermissionAllowed("id"))
    modifiedOptions = [...modifiedOptions, manueOptions[1]];
  if (isSectionPermissionAllowed("acd"))
    modifiedOptions = [...modifiedOptions, manueOptions[2]];


  const handleDashboard = (current) => {
    let option = [...modifiedOptions];
    ;
    if (current === PATH.IMMUNIZATION_DASHBOARD) {
      if (option.length > 2) return setDashboard(option[1]);
      else return setDashboard(option[0]);
    }
    if (current === PATH.ANC_DASHBOARD) {
      return setDashboard(option[option.length - 1]);
    }

    return setDashboard(option[0]);
  };

  const [selectedState, setselectedState] = useState(null);
  useEffect(() => {
    localStorage.setItem("StateId", selectedState?.value);
  }, [selectedState]);
  const changeState = (value) => {
    setselectedState({ label: value?.label, value: value?.value });
    ;
  };
  useEffect(() => {
    let option = [...modifiedOptions];
    ;
    if (location.pathname === PATH.MAIN_DASHBOARD) {
      return setDashboard({ value: 1, label: "Global Dashboard" });
    }
    if (location.pathname === PATH.IMMUNIZATION_DASHBOARD) {
      return setDashboard({ value: 2, label: "Immunization" });
    }
    if (location.pathname === PATH.ANC_DASHBOARD) {
      return setDashboard({ value: 3, label: "Antenatal Care" });
    }
    return setDashboard(option[0]);
  }, [])
  return (
    <>
      {/*begin::Header*/}
      <div
        className={`header ${layoutProps.headerClasses}`}
        id="kt_header"
        {...layoutProps.headerAttributes}
      >
        {/*begin::Container*/}
        <div
          className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between`}
        >
          <AnimateLoading />
          {/*begin::Header Menu Wrapper*/}
          {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />}
          <>
            {(location.pathname === PATH.ANC_DASHBOARD ||
              location.pathname === PATH.IMMUNIZATION_DASHBOARD ||
              location.pathname === PATH.MAIN_DASHBOARD) && (
                <div
                  className="header-menu-wrapper header-menu-wrapper-left"
                  id="kt_header_menu_wrapper"
                >
                  <div
                    id="kt_header_menu"
                    className="header-menu header-menu-mobile align-items-center"
                  >
                    <Select
                      options={modifiedOptions}
                      styles={reactSelectCustom}
                      className="w230"
                      onChange={(value) => handleOnChange(value)}
                      value={dashboard}
                    />
                    {/* {user && user.data && user.data.roles[0] === "SuperAdmin" && (
                    <Select
                      options={(locationReducer?.states || []).map((item) => {
                        return {
                          value: item.id,
                          label: item.locationName,
                        };
                      })}
                      className="d-inline-block w-75 ml-2"
                      placeholder="Select"
                      onChange={(value) => changeState(value)}
                      value={selectedState}
                      // isClearable
                      // isLoading={clinics_reducer.getClinicListLoading}
                      // isDisabled={!!clinics_reducer.getClinicListLoading}
                    />
                  )} */}
                  </div>
                </div>
              )}
          </>

          {!layoutProps.menuHeaderDisplay && <div />}

          {/*begin::Topbar*/}
          <Topbar />
          {/*end::Topbar*/}
        </div>
        {/*end::Container*/}
      </div>
      {/*end::Header*/}
      <SessionExpireModal />
    </>
  );
}

function SessionExpireModal() {
  let history = useHistory();
  const reLogin = () => {
    window.localStorage.removeItem("tokenExpiry");
    logout()
      .then((resp) => resp.data)
      .then((data) => { })
      .catch((response) => { })
      .finally(() => {
        history.push("/logout");
      });
  };
  const genericReducer = useSelector((state) => state.genericReducer);
  return (
    <Modal size="sm" centered show={genericReducer.sessionExpire}>
      <Modal.Body closeButton className="text-center mt-3">
        <h4>
          Session Expired
          <br />
          <small className="mt-2">
            Your current session has been expired, please login again to
            conitnue
          </small>
        </h4>
        <Button
          size="sm"
          className="mt-3"
          variant="primary"
          onClick={() => reLogin()}
        >
          Re-Login
        </Button>
      </Modal.Body>
    </Modal>
  );
}
